import { LargeHeader } from './large-header/large-header';
import { BaseAndMediumHeader } from './base-and-medium-header/base-and-medium-header';
import { THeaderProps } from './header.types';

export function Header({
  topMenu,
  mainMenu,
  logo,
  logoMobile,
  findPropertyPage,
  loginPage,
  bookValuationPage,
  isLoggedIn = false,
  reviewLinks,
}: THeaderProps) {
  return (
    <div className="sticky top-0 z-30">
      <div className="h-full w-full sm:hidden md:hidden">
        <LargeHeader
          logo={logo}
          topMenu={topMenu}
          mainMenu={mainMenu}
          findPropertyPage={findPropertyPage}
          loginPage={loginPage}
          bookValuationPage={bookValuationPage}
          isLoggedIn={isLoggedIn}
          reviewLinks={reviewLinks}
        />
      </div>
      <div className="lg:hidden">
        <BaseAndMediumHeader
          logoMobile={logoMobile}
          topMenu={topMenu}
          mainMenu={mainMenu}
          findPropertyPage={findPropertyPage}
          loginPage={loginPage}
          bookValuationPage={bookValuationPage}
          isLoggedIn={isLoggedIn}
        />
      </div>
    </div>
  );
}
